<template>
  <v-container fill-height fluid class="pa-0 ma-0 text-center">
    <v-row justify="center" class="pt-10">
      <span class="text-md-h4 text-uppercase"> EXPLORE BAJA CALIFORNIA </span>
    </v-row>
    <v-row justify="center" class="pt-10">
      <span class="text-md-h5"> Your Adventure Awaits </span>
    </v-row>
    <v-row justify="center">
      <h1 class="text-md-h6">Other services</h1>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>
    <v-row justify="center">
      <div class="d-flex fill-height fluid mt-8 mb-8 mx-8" style="width: 100%">
        <v-row justify="start" align="center">
          <v-col
            v-for="(item, index) in cardItems"
            :key="index"
            class="d-flex child-flex"
            cols="4"
          >
            <div class="fill-height blend-opacity">
              <v-card
                class="rounded-lg zoom-img"
                height="auto"
                width="100%"
                outlined
                hover
                elevation="10"
                :to="item.link"
              >
                <v-img
                  :src="item.backimageurl"
                  class="white--text align-end justify-start zoom-image"
                  position="center"
                  height="350"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <div class="white--text mx-2">
                    <p class="text-left font-weight-light text-h5">
                      {{ item.title }}
                    </p>
                  </div>
                </v-img>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OtherServicesComponent",
  data: () => ({
    cardItems: [
      {
        backimageurl: require("@/assets/images/ejemplo-de-anuncio-restaurante.jpg"),
        title: "Restaurants",
        link: "/restaurants",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/leisures-568x492.jpg"),
        title: "Places and Leisure",
        link: "/leisures",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/other-service-hotels.jpg"),
        title: "Hotels",
        link: "/hotels",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/loby-farmacia-1-1024x576.jpg"),
        title: "Pharmacies",
        link: "/pharmaceutics",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/laboratory-clinic.jpg"),
        title: "Laboratories",
        link: "/laboratories",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/otros-servicios-transporte.jpg"),
        title: "Transportation",
        link: "/transportations",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/vinos_baja_california.jpg"),
        title: "Vineyards",
        link: "/vineyards",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/house-restoration.png"),
        title: "Recovery houses",
        link: "/houses",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/art_culture.png"),
        title: "Art & Culture",
        link: "/artculture",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/experiencies.png"),
        title: "Experiencies",
        link: "/experiencies",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/health.png"),
        title: "Health & Wellness",
        link: "/healthwellness",
        flex: 4,
      },
      {
        backimageurl: require("@/assets/images/spa.png"),
        title: "Med Spa",
        link: "/medspa",
        flex: 4,
      },

    ],
  }),
};
</script>

<style scoped>
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 10px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
.blend-opacity {
  border-color: black;
  border-radius: 10px;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: darken;
}
.blend-opacity:hover {
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}
.zoom-img {
  overflow: hidden;
  border-radius: 10px;
}
.zoom-img > img {
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}
.zoom-img:hover > img {
  transform: scale(1.05);
}
.zoom-image {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  /* VERZÖGERUNG */
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}
.zoom-image:hover {
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -ms-transform: scale(1.07);
  -o-transform: scale(1.07);
  transform: scale(1.07);
}
</style>
